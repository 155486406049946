import { SignupFormContact, SignupFormEntity } from "./data/useSignupForm";

const publicBaseUrl = process.env.REACT_APP_API_BASE ?? "http://localhost:7071/api/public"

const getFylker = async (endpoint: string): Promise<{ fylker: IFylke[], kommuner: IKommune[] }> => {
    const resp = await fetch(`${publicBaseUrl}/fylker/${endpoint}`);
    const data = (await resp.json()) as IKommune[];

    const newFylke: { [key: string]: IFylke } = {};
    for (const fylke of data) {
        newFylke[fylke.fylkesnummer] = {
            fylke: fylke.fylke,
            fylkesnummer: fylke.fylkesnummer,
        } as IFylke;
    }

    return {
        fylker: Object.values(newFylke).sort((a, b) => `${a.fylke}`.localeCompare(`${b.fylke}`)),
        kommuner: (data || []).sort((a, b) => `${a.kommune}`.localeCompare(`${b.kommune}`)),
    }
}

const processName = (skole: ISkole): ISkole => {
    if (skole.Karakteristikk && typeof (skole.Karakteristikk) === "string" && skole.Karakteristikk.length) {
        skole.Navn = `${skole.Navn} (${skole.Karakteristikk})`
    }

    return skole;
}

const getEntityData = async (endpoint: string, fylkesnummer: string, kommunenummer: string) => {
    const resp = await fetch(`${publicBaseUrl}/entities/${endpoint}/${fylkesnummer}/${kommunenummer}`);
    const data = await resp.json();
    return (data || []).map((d: any) => d.data)
}


export const CONTRACTPDF_LINKS: { [key: string]: string } = {
    BARNEHAGE: "/pdf/vilkar-bhg-2023.pdf",
    SKOLE: "/pdf/vilkar-skole-2023.pdf"
}

export const SERVICES = {
    getSkoleFylker: async (): Promise<{ fylker: IFylke[], kommuner: IKommune[] }> => getFylker('skole'),
    getBarnehageFylker: async (): Promise<{ fylker: IFylke[], kommuner: IKommune[] }> => getFylker('barnehage'),
    getSkoler: async (fylkesnummer: string, kommunenummer: string): Promise<ISkole[]> => {
        const data = await getEntityData("skole", fylkesnummer, kommunenummer) as ISkole[]
        return data
            .map(s => processName(s))
            .sort((a, b) => `${a.Navn}`.localeCompare(`${b.Navn}`))
    },
    getBarnehager: async (fylkesnummer: string, kommunenummer: string): Promise<IBarnehage[]> => {
        const data = await getEntityData("barnehage", fylkesnummer, kommunenummer) as IBarnehage[]
        return data.sort((a, b) => `${a.navn}`.localeCompare(`${b.navn}`))
    },
    registerContract: async (entityData: SignupFormEntity, submitter: SignupFormContact, departmentleader: SignupFormContact, leader: SignupFormContact): Promise<boolean> => {
        const data = {
            entity: entityData,
            leader: leader,
            departmentleader: departmentleader,
            submitter: submitter,
        }

        const resp = await fetch(`${publicBaseUrl}/contract`, {
            method: "POST",
            body: JSON.stringify(data),
        });

        return resp.status === 200
    },
    postAnalyticsData: async (sessionId: string, step: number, entityData: SignupFormEntity, submitter: SignupFormContact, departmentleader: SignupFormContact, leader: SignupFormContact): Promise<boolean> => {
        const data = {
            sessionId: sessionId,
            step: step,
            entity: entityData,
            leader: leader,
            departmentleader: departmentleader,
            submitter: submitter,
        }

        const resp = await fetch(`${publicBaseUrl}/analytics`, {
            method: "POST",
            body: JSON.stringify(data),
        });

        return resp.status === 200
    },
    registerContractSignature: async (code: string): Promise<boolean> => {
        const resp = await fetch(`${publicBaseUrl}/signature`, {
            method: "POST",
            body: JSON.stringify({
                code: code
            }),
        });

        return resp.status === 202
    },
    getSignatureData: async (code: string) => {
        const resp = await fetch(`${publicBaseUrl}/signature/${code}`);
        return await resp.json();
    },
    getContractByOrgnr: async (orgnr: string) => {
        const resp = await fetch(`${publicBaseUrl}/contract/${orgnr}`);
        return await resp.json();
    },
}