import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { SignupFormContact, SignupFormResp } from "../data/useSignupForm";
import { StepValidateDetails } from "../data/useSignupFormValidator";
import { validPhoneNumber } from "../validatorUtils";
import { WhiteTextField } from "../styles/styledTextField";
import { InfoTooltip } from "../styles/styledTooltip";

export interface PersonContactFormProps {
  title: string;
  entityType: string;
  signupdata: SignupFormResp;
  stepValidation: StepValidateDetails;
  info?: {
    text: string;
    description: string;
    width: string;
  };
}

const tryParsePhoneNumber = (phone: string) => {
  const phoneWithoutSpaces = phone.replace(/ /g, "");

  if (`${phone}`.length >= 8 && validPhoneNumber(phoneWithoutSpaces, false))
    return "+47 " + phoneWithoutSpaces;

  if (`${phone}`.length >= 8 && validPhoneNumber(phoneWithoutSpaces, true)) {
    if (phone.startsWith("+474") || phone.startsWith("+479")) {
      return "+47 " + phoneWithoutSpaces.substring(3);
    } else {
      return phoneWithoutSpaces;
    }
  }

  return phone;
};

export default function PersonContactForm(props: PersonContactFormProps) {
  let contact: SignupFormContact | null;
  let patchChangeFunction: CallableFunction;
  if (props.entityType === "submitter") {
    contact = props.signupdata.submitter;
    patchChangeFunction = props.signupdata.patchSubmitter;
  } else if (props.entityType === "leader") {
    contact = props.signupdata.leader;
    patchChangeFunction = props.signupdata.patchLeader;
  } else if (props.entityType === "departmentleader") {
    contact = props.signupdata.departmentleader;
    patchChangeFunction = props.signupdata.patchDepartmentleader;
  } else {
    return <div>Unsupported</div>;
  }

  return (
    <>
      <Typography
        component="h2"
        sx={{ fontWeight: "bold", width: props.info?.width }}
      >
        {props.title}
        {props.info && (
          <InfoTooltip
            title={props.info.text}
            description={props.info.description}
          >
            <div
              style={{
                float: "right",
                padding: "0px 0px 0px 10px",
                cursor: "pointer",
              }}
            >
              <InfoOutlinedIcon />
            </div>
          </InfoTooltip>
        )}
      </Typography>

      <Grid container spacing={3} sx={{ marginTop: 0 }}>
        <Grid item xs={12}>
          <WhiteTextField
            required
            label="Navn"
            variant="filled"
            fullWidth
            error={
              props.stepValidation.dirtyStep &&
              props.stepValidation.validStepDetails["navn"]
                ? true
                : false
            }
            helperText={
              props.stepValidation.dirtyStep
                ? props.stepValidation.validStepDetails["navn"]
                : null
            }
            value={contact.navn}
            onChange={(e) => {
              patchChangeFunction({
                navn: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <WhiteTextField
            required
            label="Mobiltelefon"
            variant="filled"
            placeholder="+47 00000000"
            fullWidth
            value={contact.telefon}
            error={
              props.stepValidation.dirtyStep &&
              props.stepValidation.validStepDetails["telefon"]
                ? true
                : false
            }
            helperText={
              props.stepValidation.dirtyStep
                ? props.stepValidation.validStepDetails["telefon"]
                : undefined
            }
            onChange={(e) => {
              patchChangeFunction({
                telefon: tryParsePhoneNumber(e.target.value),
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <WhiteTextField
            required
            label="E-postadresse"
            variant="filled"
            fullWidth
            value={contact.epost}
            error={
              props.stepValidation.dirtyStep &&
              props.stepValidation.validStepDetails["epost"]
                ? true
                : false
            }
            helperText={
              props.stepValidation.dirtyStep
                ? props.stepValidation.validStepDetails["epost"]
                : null
            }
            onChange={(e) => {
              patchChangeFunction({
                epost: e.target.value,
              });
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
