import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Autocomplete, CircularProgress } from "@mui/material";
import { SignupFormResp } from "../data/useSignupForm";
import { useSkoleData } from "../data/useSkoleData";
import { useSkoleFylkeData } from "../data/useSkoleFylkeData";
import { StepValidateDetails } from "../data/useSignupFormValidator";
import { WhiteTextField } from "../styles/styledTextField";

export interface FinnSkoleFormProps {
  signupdata: SignupFormResp;
  stepValidation: StepValidateDetails;
}

export default function FinnSkoleForm(props: FinnSkoleFormProps) {
  const [selectedFylke, setSelectedFylke] = useState<withLabel<IFylke> | null>(
    props.signupdata.entityData.fylke
      ? {
          label: props.signupdata.entityData.fylke,
          fylke: props.signupdata.entityData.fylke,
          fylkesnummer: props.signupdata.entityData.fylkesnummer,
        }
      : null
  );

  const [selectedKommune, setSelectedKommune] =
    useState<withLabel<IKommune> | null>(
      props.signupdata.entityData.fylke && props.signupdata.entityData.kommune
        ? {
            label: props.signupdata.entityData.kommune,
            fylke: props.signupdata.entityData.fylke,
            fylkesnummer: props.signupdata.entityData.fylkesnummer,
            kommune: props.signupdata.entityData.kommune,
            kommunenummer: props.signupdata.entityData.kommunenummer,
          }
        : null
    );

  const [selectedSkole, setSelectedSkole] = useState<withLabel<ISkole> | null>(
    props.signupdata.entityData.navn
      ? {
          label: props.signupdata.entityData.navn,
          Navn: props.signupdata.entityData.navn,
          Orgnr: props.signupdata.entityData.orgnr,
          Fylke: {
            Navn: props.signupdata.entityData.fylke,
            Fylkesnr: props.signupdata.entityData.fylkesnummer,
          },
          Kommune: {
            Navn: props.signupdata.entityData.kommune,
            Kommunenr: props.signupdata.entityData.kommunenummer,
          },
          Beliggenhetsadresse: {
            Adresse: props.signupdata.entityData.address,
            Postnr: props.signupdata.entityData.postalcode,
            Poststed: props.signupdata.entityData.postalname,
            Land: "",
          },
          AnsatteFra: 0,
          AnsatteTil: 0,
          Elevtall: 0,
        }
      : null
  );

  const { visibleSkoler } = useSkoleData({
    selectedFylke,
    selectedKommune,
  });
  const { visibleFylker, visibleKommuner, loading } = useSkoleFylkeData();

  useEffect(() => {
    props.signupdata.patchEntityData({
      navn: selectedSkole?.Navn ?? "",
      orgnr: selectedSkole?.Orgnr ?? "",
      address: selectedSkole?.Beliggenhetsadresse.Adresse ?? "",
      postalcode: selectedSkole?.Beliggenhetsadresse.Postnr ?? "",
      postalname: selectedSkole?.Beliggenhetsadresse.Poststed ?? "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSkole]);

  useEffect(() => {
    props.signupdata.patchEntityData({
      fylke: selectedFylke?.fylke ?? "",
      fylkesnummer: selectedFylke?.fylkesnummer ?? "",
      kommune: selectedKommune?.kommune ?? "",
      kommunenummer: selectedKommune?.kommunenummer ?? "",
    });

    if (!selectedKommune) setSelectedSkole(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFylke, selectedKommune]);

  useEffect(() => {
    if (!selectedFylke) setSelectedKommune(null);
  }, [selectedFylke]);

  return (
    <>
      {loading && (
        <center>
          <CircularProgress />
        </center>
      )}

      {!loading && (
        <>
          <Typography sx={{ marginBottom: 3, marginTop: 2 }}>
            Detaljer om din skole
          </Typography>
          <Typography component="h2" sx={{ fontWeight: "bold" }}>
            2. Finn din skole
          </Typography>
          <Grid container spacing={3} sx={{ marginTop: 0 }}>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                value={selectedFylke}
                id="fylke-selector"
                options={visibleFylker}
                onChange={(e: any, newValue: withLabel<IFylke> | null) =>
                  setSelectedFylke(newValue)
                }
                renderInput={(params) => (
                  <WhiteTextField
                    {...params}
                    label="Fylke"
                    variant="filled"
                    error={
                      props.stepValidation.dirtyStep &&
                      props.stepValidation.validStepDetails["fylkesnummer"]
                        ? true
                        : false
                    }
                    helperText={
                      props.stepValidation.dirtyStep
                        ? props.stepValidation.validStepDetails["fylkesnummer"]
                        : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                disabled={selectedFylke ? false : true}
                value={selectedKommune}
                id="kommune-selector"
                options={visibleKommuner.filter(
                  (k) => k.fylkesnummer === selectedFylke?.fylkesnummer
                )}
                renderInput={(params) => (
                  <WhiteTextField
                    {...params}
                    label="Kommune"
                    variant="filled"
                    error={
                      props.stepValidation.dirtyStep &&
                      props.stepValidation.validStepDetails["kommunenummer"]
                        ? true
                        : false
                    }
                    helperText={
                      props.stepValidation.dirtyStep
                        ? props.stepValidation.validStepDetails["kommunenummer"]
                        : null
                    }
                  />
                )}
                onChange={(e: any, newValue: withLabel<IKommune> | null) =>
                  setSelectedKommune(newValue)
                }
              />
            </Grid>

            {!props.signupdata.customOrganization && (
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="skole-selector"
                  disabled={selectedKommune ? false : true}
                  value={selectedSkole}
                  options={visibleSkoler}
                  renderInput={(params) => (
                    <WhiteTextField
                      {...params}
                      label="Skole"
                      variant="filled"
                      error={
                        props.stepValidation.dirtyStep &&
                        props.stepValidation.validStepDetails["entitydropdown"]
                          ? true
                          : false
                      }
                      helperText={
                        props.stepValidation.dirtyStep
                          ? props.stepValidation.validStepDetails[
                              "entitydropdown"
                            ]
                          : null
                      }
                    />
                  )}
                  onChange={(e: any, newValue: withLabel<ISkole> | null) =>
                    setSelectedSkole(newValue)
                  }
                />
              </Grid>
            )}
          </Grid>
        </>
      )}
    </>
  );
}
