import { useEffect, useState } from "react";
import { SERVICES } from "../services";

export interface useSkoleDataResp {
  skoler: ISkole[];
  visibleSkoler: withLabel<ISkole>[];
  loading: boolean;
}
export interface useSkoleDataProps {
  selectedFylke: IFylke | null;
  selectedKommune: IKommune | null;
}

export const useSkoleData = (props: useSkoleDataProps): useSkoleDataResp => {
  const { selectedFylke, selectedKommune } = props;
  const [loading, setLoading] = useState(true);
  const [skoler, setSkoler] = useState<ISkole[]>([]);

  useEffect(() => {
    const load = async () => {
      if (selectedFylke && selectedKommune) {
        setLoading(true);
        const newSkoler = await SERVICES.getSkoler(
          selectedFylke.fylkesnummer,
          selectedKommune.kommunenummer
        );
        setSkoler(newSkoler);
        setLoading(false);
      }
    };

    if (selectedFylke && selectedKommune) {
      load();
    }
  }, [selectedFylke, selectedKommune]);

  const visibleSkoler = (skoler || []).map(
    (d) =>
      ({
        label: d.Navn,
        ...d,
      } as withLabel<ISkole>)
  );

  return {
    loading,
    skoler,
    visibleSkoler,
  };
};
