import FinnBarnehageForm from "./FinnBarnehageForm";
import FinnSkoleForm from "./FinnSkoleForm";
import { SignupFormResp } from "../data/useSignupForm";
import { Button } from "@mui/material";
import { StepValidateDetails } from "../data/useSignupFormValidator";

export interface FinnEntityFormProps {
  signupdata: SignupFormResp;
  stepValidation: StepValidateDetails;
  nextStep: CallableFunction;
}

export default function FinnEntityForm(props: FinnEntityFormProps) {
  return (
    <>
      {props.signupdata.entityData.entityType === "BARNEHAGE" && (
        <>
          <FinnBarnehageForm
            signupdata={props.signupdata}
            stepValidation={props.stepValidation}
          />
          <Button
            variant="text"
            sx={{ marginTop: "35px", textTransform: "none" }}
            onClick={() => {
              props.nextStep();
              props.signupdata.setCustomOrganization(true);
            }}
          >
            Finner du ikke barnehagen din?
          </Button>
        </>
      )}
      {props.signupdata.entityData.entityType === "SKOLE" && (
        <>
          <FinnSkoleForm
            signupdata={props.signupdata}
            stepValidation={props.stepValidation}
          />
          <Button
            sx={{ marginTop: "35px", textTransform: "none" }}
            onClick={() => {
              props.nextStep();
              props.signupdata.setCustomOrganization(true);
            }}
          >
            Finner du ikke skolen din?
          </Button>
        </>
      )}
    </>
  );
}
