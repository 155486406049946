/* eslint-disable jsx-a11y/alt-text */
import { ReactNode, useState } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { SignupFormContact, SignupFormEntity } from "../data/useSignupForm";
import { CONTRACTPDF_LINKS } from "../services";
import { getEntityName, getLeaderName } from "../constants";

export interface SignupInfoTableProps {
  entityData: SignupFormEntity;
  submitter: SignupFormContact;
  departmentLeader: SignupFormContact;
  leader: SignupFormContact;
  allowSignature?: boolean;
  confirmSignature?: CallableFunction;
  showErrorSigning?: boolean;
}

interface SignupInfoTableCellProps {
  title: string;
  children: ReactNode;
}

const SignupInfoTableCell = (props: SignupInfoTableCellProps) => {
  return (
    <Box sx={{ margin: 0 }}>
      <Typography sx={{ fontWeight: "bold", marginTop: 2 }}>
        {props.title}
      </Typography>

      <Box
        sx={{
          background: "white",
          marginTop: 1,
          padding: 2,
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export default function SignupInfoTable(props: SignupInfoTableProps) {
  const theme = useTheme();
  const largerScreen = useMediaQuery(theme.breakpoints.up("md"));
  const leaderName = getLeaderName(props.entityData.entityType);
  const entityName = getEntityName(props.entityData.entityType);

  const [checked, setChecked] = useState(false);
  const handleChange = (e: any) => {
    setChecked(e.target.checked);
  };

  const handleConfirmClick = () => {
    if (props.confirmSignature) props.confirmSignature();
  };

  return (
    <Grid container spacing={3} sx={{ marginTop: 0 }}>
      <Grid item xs={12} md={props.allowSignature ? 6 : 12}>
        <SignupInfoTableCell title={entityName}>
          {props.entityData.navn}
          <br />
          Org.nr: {props.entityData.orgnr}
        </SignupInfoTableCell>

        {props.entityData.entityType === "SKOLE" && (
          <SignupInfoTableCell title={"SFO"}>
            {props.entityData.avdelingsnavn}
          </SignupInfoTableCell>
        )}

        <SignupInfoTableCell title="Adresse">
          {props.entityData.address}
          <br />
          {props.entityData.postalcode} {props.entityData.postalname}
          <br />
          {props.entityData.kommune} ({props.entityData.fylke})
        </SignupInfoTableCell>

        <SignupInfoTableCell title="Litt om deg">
          {props.submitter.navn}
          <br />
          Tlf: {props.submitter.telefon}
          <br />
          {props.submitter.epost}
          <br />
        </SignupInfoTableCell>

        {props.entityData.entityType === "SKOLE" && (
          <SignupInfoTableCell title="SFO-leder">
            {props.departmentLeader.navn}
            <br />
            Tlf: {props.departmentLeader.telefon}
            <br />
            {props.departmentLeader.epost}
            <br />
          </SignupInfoTableCell>
        )}

        <SignupInfoTableCell title={leaderName}>
          {props.leader.navn}
          <br />
          Tlf: {props.leader.telefon}
          <br />
          {props.leader.epost}
          <br />
        </SignupInfoTableCell>
      </Grid>

      {!props.allowSignature && (
        <Grid item xs={12}>
          <Typography component="h2" sx={{ fontWeight: "bold" }}>
            Vilkår
          </Typography>

          <Typography gutterBottom>
            Dette er vilkårene som {leaderName.toLocaleLowerCase()} vil få
            tilsendt på epost for godkjenning. Du kan laste ned en kopi av disse
            her.
          </Typography>

          <Button
            sx={{ marginTop: "15px", textTransform: "none" }}
            variant="text"
            component="a"
            target="_blank"
            href={CONTRACTPDF_LINKS[props.entityData.entityType]}
          >
            Last ned kopi vilkår (PDF) &raquo;
          </Button>
        </Grid>
      )}

      {props.allowSignature && (
        <>
          <Grid item md={6} xs={12}>
            <Typography variant="h5" sx={{ marginTop: "25px" }}>
              Godkjenning av vilkår
            </Typography>
            <Typography gutterBottom>
              Se over informasjonen og les gjennom vilkårene.
            </Typography>
            <Grid container spacing={3} sx={{ marginTop: 0 }}>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  component="a"
                  target="_blank"
                  href={CONTRACTPDF_LINKS[props.entityData.entityType]}
                >
                  Last ned vilkår (PDF)
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  marginTop: {
                    md: "50px",
                    sx: "15px",
                  },
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Jeg har lest og aksepterer vilkårene"
                />
              </Grid>
            </Grid>

            {props.showErrorSigning && (
              <Alert severity="error">
                <AlertTitle>Feil med signering</AlertTitle>
                Det oppsto en feil ved registrering av din signatur. Prøv igjen
                eller kontakt oss på{" "}
                <a href="mailto:post@matjungelen.no">post@matjungelen.no</a>
              </Alert>
            )}
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                disabled={!checked}
                onClick={handleConfirmClick}
                disableElevation
                sx={{ mt: 3, ml: 1 }}
              >
                Bekreft påmelding
              </Button>
            </Box>
          </Grid>

          {largerScreen && (
            <>
              <Grid item sm={12} sx={{ marginTop: "35px" }}>
                <Typography variant="h5" sx={{ marginBottom: "15px" }}>
                  Vilkår
                </Typography>
                <object
                  id="pdfView"
                  data={CONTRACTPDF_LINKS[props.entityData.entityType]}
                  style={{ width: "calc(100% - 20px)", minHeight: "800px" }}
                ></object>
              </Grid>
              <Grid container sx={{ marginTop: "25px", padding: "25px" }}>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Jeg har lest og aksepterer vilkårene"
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    variant="contained"
                    disabled={!checked}
                    disableElevation
                    onClick={handleConfirmClick}
                  >
                    Bekreft påmelding
                  </Button>
                </Grid>
              </Grid>
              {props.showErrorSigning && (
                <Grid container sx={{ marginTop: "25px", padding: "25px" }}>
                  <Grid item xs={6}></Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Alert severity="error">
                      <AlertTitle>Feil med signering</AlertTitle>
                      Det oppsto en feil ved registrering av din signatur. Prøv
                      igjen eller kontakt oss på{" "}
                      <a href="mailto:post@matjungelen.no">
                        post@matjungelen.no
                      </a>
                    </Alert>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </>
      )}
    </Grid>
  );
}
