import SignupInfoTable from "./SignupInfoTable";
import { CircularProgress, Typography } from "@mui/material";
import { useSignatureData } from "../data/useSignatureData";
import { StyledPaperAmber } from "../styles/styledComponents";

export interface SignatureFormProps {
  code?: string;
}

export default function SignatureForm(props: SignatureFormProps) {
  const {
    found,
    loading,
    successSigning,
    failedSigning,
    signatureData,
    confirmSignature,
  } = useSignatureData(props.code);

  const renderContent = () => {
    if (loading) {
      return (
        <center>
          <CircularProgress sx={{ marginTop: "35px" }} />
        </center>
      );
    }

    if (successSigning) {
      return (
        <>
          <Typography variant="h6" gutterBottom>
            Tusen takk
          </Typography>
          <Typography gutterBottom component="div">
            {signatureData?.contract?.entity?.entityType === "BARNEHAGE" && (
              <p>
                Dere er nå påmeldt i Matjungelen! Bekreftelse er sendt på epost
                og du får snart tilsendt mer info på mail.{" "}
              </p>
            )}
            {signatureData?.contract?.entity?.entityType === "SKOLE" && (
              <p>
                Dere er nå påmeldt i Matjungelen! Bekreftelse er sendt på epost
                og SFO-leder får snart tilsendt mer info på mail.
              </p>
            )}
            <p>
              Ta kontakt på{" "}
              <a href="mailto:post@matjungelen.no">post@matjungelen.no</a> hvis
              du har spørsmål i mellomtiden.
            </p>
            <p>Velkommen i Matjungelen!</p>
          </Typography>
        </>
      );
    }

    if (found) {
      return (
        <SignupInfoTable
          entityData={signatureData?.contract.entity}
          submitter={signatureData?.contract.submitter}
          departmentLeader={signatureData?.contract.departmentleader}
          leader={signatureData?.contract.leader}
          allowSignature={true}
          confirmSignature={confirmSignature}
          showErrorSigning={failedSigning}
        />
      );
    }

    return (
      <>
        <Typography variant="h6" gutterBottom>
          Link ikke gyldig
        </Typography>
        <Typography gutterBottom>
          <p>
            Linken er ikke gyldig. Dette er fordi vilkårene alleredeer godkjent
            eller du har oppgitt en ugyldig link
          </p>
          <p>
            Ta kontakt på{" "}
            <a href="mailto:post@matjungelen.no">post@matjungelen.no</a> hvis du
            har spørsmål.
          </p>
        </Typography>
      </>
    );
  };

  return (
    <StyledPaperAmber
      variant="outlined"
      sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 }, background: "red" }}
    >
      <Typography component="h1" variant="h4" align="center">
        Signering
      </Typography>

      {renderContent()}
    </StyledPaperAmber>
  );
}
