import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { SignupFormResp } from "../data/useSignupForm";
import { useContractData } from "../data/useContractData";
import { Alert, AlertTitle } from "@mui/material";
import { StepValidateDetails } from "../data/useSignupFormValidator";
import { validOrgNr } from "../validatorUtils";
import { WhiteTextField } from "../styles/styledTextField";
import ClickableInfoBox from "./ClickableInfoBox";

export interface EntityDetailsFormProps {
  signupdata: SignupFormResp;
  stepValidation: StepValidateDetails;
}

export default function EntityDetailsForm(props: EntityDetailsFormProps) {
  const { foundExistingContract } = useContractData(
    props.signupdata.entityData.orgnr
  );

  let alreadyFoundWarning = "";
  let showAvdelingNavn: boolean = false;
  let avdelingNavnTitle: string = "";

  if (props.signupdata.entityData.entityType === "SKOLE") {
    showAvdelingNavn = true;
    avdelingNavnTitle = "Navn på SFO";
    alreadyFoundWarning = `Sjekk om din skole eller SFO allerede er påmeldt for å unngå dobbeltregistrering. `;
  } else {
    alreadyFoundWarning = `Sjekk om din barnehage allerede er påmeldt for å unngå dobbeltregistrering. `;
  }

  const inputFieldsDisabled = props.signupdata.entityData.navn ? false : true;
  const organizationInputFieldsDisabled = !props.signupdata.customOrganization;

  return (
    <>
      <Typography component="h2" sx={{ fontWeight: "bold" }}>
        3. Flere detaljer om din{" "}
        {props.signupdata.entityData.entityType === "BARNEHAGE"
          ? "barnehage"
          : "skole"}
      </Typography>
      <Grid container spacing={3} sx={{ marginTop: 0 }}>
        {foundExistingContract && (
          <Grid item xs={12}>
            <Alert severity="warning">
              <AlertTitle>Allerede påmeldt?</AlertTitle>
              Vi har allerede en påmelding fra{" "}
              {props.signupdata.entityData.navn}. {alreadyFoundWarning}Hvis du
              har spørsmål kan du gjerne sende en e-post til{" "}
              <a href="mailto:post@matjungelen.no">post@matjungelen.no</a>.
            </Alert>
          </Grid>
        )}

        <Grid item xs={12}>
          <WhiteTextField
            disabled={organizationInputFieldsDisabled}
            required
            value={props.signupdata.entityData.navn}
            onChange={(e) => {
              props.signupdata.patchEntityData({ navn: e.target.value });
            }}
            id="navn"
            label="Navn"
            variant="filled"
            fullWidth
            error={
              props.stepValidation.dirtyStep &&
              props.stepValidation.validStepDetails["navn"]
                ? true
                : false
            }
            helperText={
              props.stepValidation.dirtyStep
                ? props.stepValidation.validStepDetails["navn"]
                : null
            }
          />
        </Grid>

        <Grid item xs={12}>
          <WhiteTextField
            disabled={organizationInputFieldsDisabled}
            required
            value={props.signupdata.entityData.orgnr}
            onChange={(e) => {
              let newValue = e.target.value;
              if (
                `${newValue}`.length > 9 &&
                validOrgNr(newValue.replace(/ /g, ""))
              )
                newValue = newValue.replace(/ /g, "");

              props.signupdata.patchEntityData({ orgnr: newValue });
            }}
            id="orgnr"
            label="Organisasjonsnummer"
            variant="filled"
            fullWidth
            error={
              props.stepValidation.dirtyStep &&
              props.stepValidation.validStepDetails["orgnr"]
                ? true
                : false
            }
            helperText={
              props.stepValidation.dirtyStep
                ? props.stepValidation.validStepDetails["orgnr"]
                : null
            }
          />

          <ClickableInfoBox title="Ukjent organisasjonsnummer?">
            <>
              Vi trenger å knytte{" "}
              {props.signupdata.entityData.entityType === "BARNEHAGE"
                ? "barnehagens"
                : "skolens"}{" "}
              påmelding til riktig næringskode. Vi bruker derfor
              organisasjonsnummeret til underenheten og ikke hovedenheten. Ta
              kontakt på{" "}
              <a href="mailto:post@matjungelen.no">post@matjungelen.no</a> hvis
              du har flere spørsmål.
            </>
          </ClickableInfoBox>
        </Grid>

        {showAvdelingNavn && (
          <Grid item xs={12}>
            <WhiteTextField
              disabled={inputFieldsDisabled}
              required
              value={props.signupdata.entityData.avdelingsnavn}
              onChange={(e) => {
                props.signupdata.patchEntityData({
                  avdelingsnavn: e.target.value,
                });
              }}
              error={
                props.stepValidation.dirtyStep &&
                props.stepValidation.validStepDetails["avdelingsnavn"]
                  ? true
                  : false
              }
              helperText={
                props.stepValidation.dirtyStep
                  ? props.stepValidation.validStepDetails["avdelingsnavn"]
                  : null
              }
              id="sfonavn"
              label={avdelingNavnTitle}
              variant="filled"
              fullWidth
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <WhiteTextField
            disabled={inputFieldsDisabled}
            required
            id="adresse"
            error={
              props.stepValidation.dirtyStep &&
              props.stepValidation.validStepDetails["address"]
                ? true
                : false
            }
            helperText={
              props.stepValidation.dirtyStep
                ? props.stepValidation.validStepDetails["address"]
                : null
            }
            value={props.signupdata.entityData.address}
            onChange={(e) => {
              props.signupdata.patchEntityData({ address: e.target.value });
            }}
            sx={{ marginTop: 3 }}
            label="Adresse"
            variant="filled"
            fullWidth
          />
        </Grid>

        <Grid item xs={4}>
          <WhiteTextField
            disabled={inputFieldsDisabled}
            required
            id="postnr"
            value={props.signupdata.entityData.postalcode}
            error={
              props.stepValidation.dirtyStep &&
              props.stepValidation.validStepDetails["postalcode"]
                ? true
                : false
            }
            helperText={
              props.stepValidation.dirtyStep
                ? props.stepValidation.validStepDetails["postalcode"]
                : null
            }
            onChange={(e) => {
              props.signupdata.patchEntityData({
                postalcode: e.target.value,
              });
            }}
            label="Postnummer"
            variant="filled"
            fullWidth
          />
        </Grid>

        <Grid item xs={8}>
          <WhiteTextField
            disabled={inputFieldsDisabled}
            required
            id="poststed"
            error={
              props.stepValidation.dirtyStep &&
              props.stepValidation.validStepDetails["postalname"]
                ? true
                : false
            }
            helperText={
              props.stepValidation.dirtyStep
                ? props.stepValidation.validStepDetails["postalname"]
                : null
            }
            value={props.signupdata.entityData.postalname}
            onChange={(e) => {
              props.signupdata.patchEntityData({
                postalname: e.target.value,
              });
            }}
            label="Poststed"
            variant="filled"
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <WhiteTextField
            disabled={inputFieldsDisabled}
            required
            value={props.signupdata.entityData.numberOfChildren}
            onChange={(e) => {
              props.signupdata.patchEntityData({
                numberOfChildren: e.target.value,
              });
            }}
            sx={{ marginTop: 3 }}
            error={
              props.stepValidation.dirtyStep &&
              props.stepValidation.validStepDetails["numberOfChildren"]
                ? true
                : false
            }
            helperText={
              props.stepValidation.dirtyStep
                ? props.stepValidation.validStepDetails["numberOfChildren"]
                : null
            }
            id="antall-barn"
            label="Antall barn"
            variant="filled"
          />
        </Grid>
        <Grid item xs={12}>
          <WhiteTextField
            disabled={inputFieldsDisabled}
            required
            value={props.signupdata.entityData.numberOfEmployees}
            onChange={(e) => {
              props.signupdata.patchEntityData({
                numberOfEmployees: e.target.value,
              });
            }}
            error={
              props.stepValidation.dirtyStep &&
              props.stepValidation.validStepDetails["numberOfEmployees"]
                ? true
                : false
            }
            helperText={
              props.stepValidation.dirtyStep
                ? props.stepValidation.validStepDetails["numberOfEmployees"]
                : null
            }
            id="antall-barn"
            label="Antall ansatte"
            variant="filled"
          />
        </Grid>
      </Grid>
    </>
  );
}
