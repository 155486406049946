import { Grid, Typography, Alert, AlertTitle } from "@mui/material";

export default function FormSentError() {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Det oppsto en feil med innsending av skjema
      </Typography>
      <Grid container spacing={3} sx={{ marginTop: 0 }}>
        <Grid item xs={12}>
          <Alert severity="error">
            <AlertTitle>Feil med innsending av skjema</AlertTitle>
            Det oppsto dessverre en feil når vi prøvde å sende inn skjema. Prøv
            igjen eller ta kontakt med oss på{" "}
            <a href="mailto:post@matjungelen.no">post@matjungelen.no</a>.
          </Alert>
        </Grid>
      </Grid>
    </>
  );
}
