import { useState } from "react";

export enum RADIO_BOOLEAN {
  TRUE = "yes",
  FALSE = "no",
  UNSET = "unset",
}

export interface SignupFormEntity {
  entityType: string;
  navn: string;
  avdelingsnavn: string;
  orgnr: string;
  fylke: string;
  fylkesnummer: string;
  kommune: string;
  kommunenummer: string;
  address: string;
  postalcode: string;
  postalname: string;
  numberOfChildren: string;
  numberOfEmployees: string;
}

export interface SignupFormEntityPath {
  entityType?: string;
  navn?: string;
  avdelingsnavn?: string;
  orgnr?: string;
  fylke?: string;
  fylkesnummer?: string;
  kommune?: string;
  kommunenummer?: string;
  address?: string;
  postalcode?: string;
  postalname?: string;
  numberOfChildren?: string;
  numberOfEmployees?: string;
}

export interface SignupFormContact {
  navn: string;
  epost: string;
  telefon: string;
}

export interface SignupFormContactPatch {
  navn?: string;
  epost?: string;
  telefon?: string;
}

export interface SignupFormResp {
  departmentLeaderIsSubmitter: RADIO_BOOLEAN;
  setDepartmentLeaderIsSubmitter: CallableFunction;
  leaderIsSubmitter: RADIO_BOOLEAN;
  setLeaderIsSubmitter: CallableFunction;
  customOrganization: boolean;
  setCustomOrganization: CallableFunction;
  entityData: SignupFormEntity;
  setEntityData: CallableFunction;
  patchEntityData: CallableFunction;
  submitter: SignupFormContact;
  setSubmitter: CallableFunction;
  patchSubmitter: CallableFunction;
  departmentleader: SignupFormContact;
  setDepartmentleader: CallableFunction;
  patchDepartmentleader: CallableFunction;
  leader: SignupFormContact;
  setLeader: CallableFunction;
  patchLeader: CallableFunction;
}

export const useSignupForm = (): SignupFormResp => {
  const [customOrganization, setCustomOrganization] = useState<boolean>(false);
  const [departmentLeaderIsSubmitter, setDepartmentLeaderIsSubmitter] =
    useState<RADIO_BOOLEAN>(RADIO_BOOLEAN.UNSET);
  const [leaderIsSubmitter, setLeaderIsSubmitter] = useState<RADIO_BOOLEAN>(
    RADIO_BOOLEAN.UNSET
  );

  const [entityData, setEntityData] = useState<SignupFormEntity>({
    entityType: "BARNEHAGE",
    navn: "",
    avdelingsnavn: "",
    orgnr: "",
    fylke: "",
    fylkesnummer: "",
    kommune: "",
    kommunenummer: "",
    address: "",
    postalcode: "",
    postalname: "",
    numberOfChildren: "",
    numberOfEmployees: "",
  });

  const [submitter, setSubmitter] = useState<SignupFormContact>({
    navn: "",
    epost: "",
    telefon: "",
  });

  const [departmentleader, setDepartmentleader] = useState<SignupFormContact>({
    navn: "",
    epost: "",
    telefon: "",
  });

  const [leader, setLeader] = useState<SignupFormContact>({
    navn: "",
    epost: "",
    telefon: "",
  });

  return {
    customOrganization,
    setCustomOrganization: (status: boolean) => {
      setCustomOrganization(status);
      setEntityData({
        ...entityData,
        ...{
          navn: "",
          orgnr: "",
        },
      });
    },
    departmentLeaderIsSubmitter,
    setDepartmentLeaderIsSubmitter,
    leaderIsSubmitter,
    setLeaderIsSubmitter,
    entityData,
    setEntityData,
    patchEntityData: (patch: SignupFormEntityPath) => {
      setEntityData({
        ...entityData,
        ...patch,
      });
    },
    submitter,
    setSubmitter,
    patchSubmitter: (patch: SignupFormContactPatch) => {
      setSubmitter({
        ...submitter,
        ...patch,
      });
    },
    departmentleader,
    setDepartmentleader,
    patchDepartmentleader: (patch: SignupFormContactPatch) => {
      setDepartmentleader({
        ...departmentleader,
        ...patch,
      });
    },
    leader,
    setLeader,
    patchLeader: (patch: SignupFormContactPatch) => {
      setLeader({
        ...leader,
        ...patch,
      });
    },
  };
};
