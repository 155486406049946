import styled from "styled-components";
import { AppBar, Paper, useMediaQuery } from "@mui/material";
import { matjungelenAmber, matjungelenGreen } from "./colors";

export const StyledPaperAmber = styled(Paper)({
    '&.MuiPaper-root': {
        background: matjungelenAmber
    }
})


export const StyledPaperGreen = styled(Paper)({
    '&.MuiPaper-root': {
        background: matjungelenGreen
    }
})

export const StyledAppBar = styled(AppBar)(props => {
    const isMd = useMediaQuery("(min-width:899px)");
    return {
        '.MuiToolbar-root': {
            background: matjungelenAmber,
            height: '83px',
            paddingLeft: isMd ? '60px' : "30px",
            paddingRight: isMd ? '80px' : '40px'
        }
    }
})
