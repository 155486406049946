import { useEffect, useState } from "react";
import { SERVICES } from "../services";

export interface useBarnehageFylkeDataResp {
  fylker: IFylke[];
  kommuner: IKommune[];
  visibleFylker: withLabel<IFylke>[];
  visibleKommuner: withLabel<IKommune>[];
  loading: boolean;
}

export const useBarnehageFylkeData = (): useBarnehageFylkeDataResp => {
  const [fylker, setFylker] = useState<IFylke[]>([]);
  const [kommuner, setKommuner] = useState<IKommune[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      const { fylker, kommuner } = await SERVICES.getBarnehageFylker();
      setFylker(fylker);
      setKommuner(kommuner);
      setLoading(false);
    };

    load();
  }, []);

  const visibleKommuner = (kommuner || []).map(
    (k) =>
      ({
        label: k.kommune,
        ...k,
      } as withLabel<IKommune>)
  );

  const visibleFylker = (fylker || []).map(
    (f: any) =>
      ({
        label: f.fylke,
        ...f,
      } as withLabel<IKommune>)
  );

  return {
    fylker,
    kommuner,
    loading,
    visibleKommuner,
    visibleFylker,
  };
};
