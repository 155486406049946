import { createTheme } from "@mui/material";
import { matjungelenGreen } from "./colors";

export const matjungelenTheme = createTheme({
    typography: {
        fontFamily: [
            '"Open Sans"',
            "Roboto",
            "-apple-system",
            "BlinkMacSystemFont",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
    },
    palette: {
        primary: {
            main: matjungelenGreen,
        },
    }
});
