export const validMobilePhoneNumber = (phone: string) => {
    if (`${phone}`.length === 8) {
        const expression: RegExp = /^[4,9]{1}[0-9]{7}$/i;
        return expression.test(phone);
    }

    return false;
};


export const validPhoneNumber = (phone: string, includePrefix: boolean) => {
    if (`${phone}`.length === 8) {
        const expression: RegExp = /^[0-9]{8}$/i;
        if (expression.test(phone)) return true;
    }

    if (includePrefix) {
        if (`${phone}`.length >= 8) {
            const expression: RegExp = /^\+47[0-9]{8}$/i;
            if (expression.test(phone)) return true;
        }

        if (`${phone}`.length >= 8) {
            const expression: RegExp = /^\+47 [0-9]{8}$/i;
            if (expression.test(phone)) return true;
        }
    }

    return false;
};

export const validOrgNr = (orgnr: string) => {
    if (`${orgnr}`.length === 9) {
        const expression: RegExp = /^[0-9]{9}$/i;
        return expression.test(orgnr);
    }

    return false;
};
