import Typography from "@mui/material/Typography";
import SignupInfoTable from "./SignupInfoTable";
import { RADIO_BOOLEAN, SignupFormResp } from "../data/useSignupForm";
import { getLeaderName } from "../constants";

export interface EntityDetailsFormProps {
  signupdata: SignupFormResp;
}

export default function SignupFormSummary(props: EntityDetailsFormProps) {
  const leaderName = getLeaderName(
    props.signupdata.entityData.entityType,
    true
  );

  return (
    <>
      <Typography component="h2" sx={{ fontWeight: "bold" }}>
        6. Oppsummering
      </Typography>
      <Typography variant="subtitle1">
        <p>
          Se gjennom påmeldingen din før du sender inn, og sjekk at alt stemmer.
        </p>
        <p>
          Når du sender inn påmeldingen, mottar {leaderName} en e-post med lenke
          for å bekrefte vilkårene og påmeldingen. Vi sender deg en bekreftelse
          når påmeldingen er godkjent.
        </p>
      </Typography>

      <SignupInfoTable
        entityData={props.signupdata.entityData}
        departmentLeader={
          props.signupdata.departmentLeaderIsSubmitter === RADIO_BOOLEAN.TRUE
            ? props.signupdata.submitter
            : props.signupdata.departmentleader
        }
        leader={props.signupdata.leader}
        submitter={props.signupdata.submitter}
      />
    </>
  );
}
