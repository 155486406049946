import {
  Box,
  Button,
  Container,
  CssBaseline,
  ThemeProvider,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import SignupForm from "./components/SignupForm";
import SignatureForm from "./components/SignatureForm";
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import { matjungelenTheme } from "./styles/matjungelenTheme";
import { StyledAppBar } from "./styles/styledComponents";

interface SignupFormAppProps {
  enhet?: string;
}

function MatjungelenAppBar() {
  const isSm = useMediaQuery("(max-width:700px)");

  return (
    <StyledAppBar
      position="absolute"
      color="default"
      elevation={0}
      sx={{
        position: "relative",
        borderBottom: (t) => `1px solid ${t.palette.divider}`,
      }}
    >
      <Container maxWidth="lg">
        <Toolbar>
          <img
            src="/images/logo.png"
            alt="Matjungelen"
            loading="lazy"
            style={{ width: "100%", maxWidth: "250px" }}
          />
          {!isSm && (
            <>
              <Box component="div" sx={{ flexGrow: 1 }}></Box>
              <Button href="https://www.matjungelen.no/" disableElevation>
                Ta meg tilbake til Matjungelen.no &raquo;
              </Button>
            </>
          )}
        </Toolbar>
      </Container>
    </StyledAppBar>
  );
}

function SignupFormApp(props: SignupFormAppProps) {
  const showMoreHeaderImg = useMediaQuery(
    "(min-width:700px) and (min-height:1000px)"
  );

  return (
    <>
      <CssBaseline />
      <MatjungelenAppBar />
      <Container component="main" maxWidth="lg" sx={{ mb: 4 }}>
        <div
          style={{
            marginTop: showMoreHeaderImg ? 100 : 25,
          }}
        >
          <SignupForm enhet={props.enhet} />
        </div>
      </Container>
    </>
  );
}

function SignupApp() {
  const params = useParams();
  const { code } = params || {};

  return (
    <>
      <CssBaseline />
      <MatjungelenAppBar />
      <Container
        component="main"
        maxWidth="lg"
        sx={{ mb: 4 }}
        className="matjungelen-content-wrapper"
      >
        <div style={{ marginTop: 100 }}>
          <SignatureForm code={code} />
        </div>
      </Container>
    </>
  );
}

function App() {
  return (
    <ThemeProvider theme={matjungelenTheme}>
      <BrowserRouter>
        <Routes>
          <Route path="/signature/:code?" element={<SignupApp />} />
          <Route
            path="/barnehage"
            element={<SignupFormApp enhet="barnehage" />}
          />
          <Route path="/sfo" element={<SignupFormApp enhet="sfo" />} />
          <Route path="*" element={<SignupFormApp />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
