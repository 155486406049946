import { useState } from "react";
import {
  RADIO_BOOLEAN,
  SignupFormContact,
  SignupFormEntity,
  SignupFormResp,
} from "./useSignupForm";
import { validOrgNr, validPhoneNumber } from "../validatorUtils";

export interface StepValidateDetails {
  dirtyStep: boolean;
  validStep: boolean;
  validStepDetails: { [key: string]: string };
}

export interface SignupFormValidatorResp {
  validStep1: StepValidateDetails;
  validStep2: StepValidateDetails;
  validStep3: StepValidateDetails;
  validStep4a: StepValidateDetails;
  validStep4b: StepValidateDetails;
  validStep5: StepValidateDetails;
  isStepDirty: CallableFunction;
  isStepValid: CallableFunction;
  setDirtyStep: CallableFunction;
}

const isEmailAddress = (email: string) => {
  if (`${email}`.length > 3) {
    const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return expression.test(email);
  }

  return false;
};

const ValidateContactDetails = (
  dirtyStep: boolean,
  contact: SignupFormContact
): StepValidateDetails => {
  const validStep =
    `${contact.navn}`.length > 0 &&
    `${contact.telefon}`.length >= 8 &&
    isEmailAddress(`${contact.epost}`);

  const validStepDetails: { [key: string]: string } = {};

  if (!(`${contact.navn}`.length > 0)) {
    validStepDetails["navn"] = "Navn er påkrevd";
  }

  if (
    !(
      validPhoneNumber(contact.telefon, true) ||
      validPhoneNumber(contact.telefon, false)
    )
  ) {
    validStepDetails["telefon"] =
      "Telefon er påkrevd. Oppgi helst et mobilnummer";
  }

  if (!isEmailAddress(`${contact.epost}`)) {
    validStepDetails["epost"] =
      "E-post er påkrevd. Må være en gyldig e-postadresse";
  }

  return {
    dirtyStep,
    validStep,
    validStepDetails,
  };
};

const ValidateEntityDetails = (
  dirtyStep: boolean,
  entityData: SignupFormEntity
): StepValidateDetails => {
  let validStep =
    `${entityData.navn}`.length > 0 &&
    `${entityData.orgnr}`.length > 0 &&
    `${entityData.address}`.length > 0 &&
    `${entityData.postalcode}`.length > 0 &&
    `${entityData.postalname}`.length > 0 &&
    `${entityData.numberOfChildren}`.length > 0 &&
    `${entityData.numberOfEmployees}`.length > 0;

  const validStepDetails: { [key: string]: string } = {};

  if (entityData.entityType === "SKOLE") {
    validStep = validStep && `${entityData.avdelingsnavn}`.length > 0;

    if (!(`${entityData.avdelingsnavn}`.length > 0))
      validStepDetails["avdelingsnavn"] = "Navn på SFO er påkrevd";
  }

  if (!(`${entityData.navn}`.length > 0))
    validStepDetails["navn"] = "Navn er påkrevd";

  if (!(`${entityData.orgnr}`.length > 0))
    validStepDetails["orgnr"] = "Organisasjonsnummer er påkrevd";
  else if (!validOrgNr(entityData.orgnr))
    validStepDetails["orgnr"] = "Organisasjonsnummer skal bestå av 9 siffer";

  if (!(`${entityData.address}`.length > 0))
    validStepDetails["address"] = "Adresse er påkrevd";
  if (!(`${entityData.postalcode}`.length > 0))
    validStepDetails["postalcode"] = "Postnummer er påkrevd";
  if (!(`${entityData.postalname}`.length > 0))
    validStepDetails["postalname"] = "Poststed er påkrevd";

  if (!(`${entityData.numberOfChildren}`.length > 0))
    validStepDetails["numberOfChildren"] = "Antall ansatte er påkrevd";
  if (!(`${entityData.numberOfEmployees}`.length > 0))
    validStepDetails["numberOfEmployees"] = "Antall barn er påkrevd";

  return {
    dirtyStep,
    validStep,
    validStepDetails,
  };
};

const ValidateFindDetails = (
  dirtyStep: boolean,
  entityData: SignupFormEntity
): StepValidateDetails => {
  let validStep =
    `${entityData.fylkesnummer}`.length > 0 &&
    `${entityData.kommunenummer}`.length > 0 &&
    `${entityData.navn}`.length > 0 &&
    `${entityData.orgnr}`.length > 0;

  const validStepDetails: { [key: string]: string } = {};

  if (!(`${entityData.fylkesnummer}`.length > 0))
    validStepDetails["fylkesnummer"] = "Fylke er påkrevd";

  if (!(`${entityData.kommunenummer}`.length > 0))
    validStepDetails["kommunenummer"] = "Kommune er påkrevd";

  if (!(`${entityData.navn}`.length > 0))
    validStepDetails["navn"] = "Navn er påkrevd";

  if (!(`${entityData.orgnr}`.length > 0))
    validStepDetails["orgnr"] = "Organisasjonsnummer er påkrevd";
  else if (!validOrgNr(entityData.orgnr))
    validStepDetails["orgnr"] = "Organisasjonsnummer skal bestå av 9 siffer";

  if (!(`${entityData.navn}`.length > 0) && entityData.entityType === "SKOLE")
    validStepDetails["entitydropdown"] = "Velg skolen din fra listen";

  if (
    !(`${entityData.navn}`.length > 0) &&
    entityData.entityType === "BARNEHAGE"
  )
    validStepDetails["entitydropdown"] = "Velg barnehagen din fra listen";

  return {
    dirtyStep,
    validStep,
    validStepDetails,
  };
};

export const useSignupFormValidator = (
  signupdata: SignupFormResp
): SignupFormValidatorResp => {
  const [dirtyStep1, setDirtyStep1] = useState<boolean>(false);
  const [dirtyStep2, setDirtyStep2] = useState<boolean>(false);
  const [dirtyStep3, setDirtyStep3] = useState<boolean>(false);
  const [dirtyStep4, setDirtyStep4] = useState<boolean>(false);
  const [dirtyStep5, setDirtyStep5] = useState<boolean>(false);

  let validStep1: StepValidateDetails = {
    dirtyStep: false,
    validStep: false,
    validStepDetails: {},
  };
  let validStep2: StepValidateDetails = {
    dirtyStep: false,
    validStep: false,
    validStepDetails: {},
  };
  let validStep3: StepValidateDetails = {
    dirtyStep: false,
    validStep: false,
    validStepDetails: {},
  };
  let validStep4a: StepValidateDetails = {
    dirtyStep: false,
    validStep: false,
    validStepDetails: {},
  };
  let validStep4b: StepValidateDetails = {
    dirtyStep: false,
    validStep: false,
    validStepDetails: {},
  };
  let validStep5: StepValidateDetails = {
    dirtyStep: false,
    validStep: false,
    validStepDetails: {},
  };

  if (signupdata) {
    // Validate Step 1
    const validEntityType =
      signupdata.entityData.entityType === "SKOLE" ||
      signupdata.entityData.entityType === "BARNEHAGE";
    validStep1 = {
      dirtyStep: dirtyStep1,
      validStep: validEntityType,
      validStepDetails: {
        entityType: validEntityType ? "" : "Velg en",
      },
    };

    // Validate Step 2
    validStep2 = ValidateFindDetails(dirtyStep2, signupdata.entityData);

    // Validate Step 3
    validStep3 = ValidateEntityDetails(dirtyStep3, signupdata.entityData);

    // Validate Step 4
    validStep4a = ValidateContactDetails(dirtyStep4, signupdata.submitter);

    if (signupdata.entityData.entityType === "BARNEHAGE") {
      if (signupdata.leaderIsSubmitter === RADIO_BOOLEAN.UNSET) {
        validStep4b = {
          dirtyStep: validStep4a.dirtyStep,
          validStep: false,
          validStepDetails: {
            leaderIsSubmitter:
              "Det er påkrevd å oppgi ja eller nei. Hvis du ikke er styrer i barnehagen skal du velge nei og oppgi flere detaljer i neste steg.",
          },
        };
      } else {
        validStep4b = {
          dirtyStep: validStep4a.dirtyStep,
          validStep: true,
          validStepDetails: {},
        };
      }
    } else if (signupdata.entityData.entityType === "SKOLE") {
      if (signupdata.departmentLeaderIsSubmitter === RADIO_BOOLEAN.UNSET) {
        validStep4b = {
          dirtyStep: validStep4a.dirtyStep,
          validStep: false,
          validStepDetails: {
            departmentLeaderIsSubmitter:
              "Det er påkrevd å oppgi ja eller nei. Hvis du ikke er SFO-leder skal du velge nei og oppgi flere detaljer i neste steg.",
          },
        };
      } else if (
        signupdata.departmentLeaderIsSubmitter === RADIO_BOOLEAN.FALSE
      ) {
        validStep4b = ValidateContactDetails(
          dirtyStep4,
          signupdata.departmentleader
        );
      } else {
        // TRUE
        validStep4b = {
          dirtyStep: validStep4a.dirtyStep,
          validStep: true,
          validStepDetails: {},
        };
      }
    }

    // Validate Step 5
    validStep5 = ValidateContactDetails(dirtyStep5, signupdata.leader);
  }

  return {
    validStep1,
    validStep2,
    validStep3,
    validStep4a,
    validStep4b,
    validStep5,
    isStepDirty: (activeStep: number): boolean => {
      switch (activeStep + 1) {
        case 1:
          return validStep1.dirtyStep;
        case 2:
          return validStep2.dirtyStep;
        case 3:
          return validStep3.dirtyStep;
        case 4:
          return validStep4a.dirtyStep || validStep4b.dirtyStep;
        case 5:
          return validStep5.dirtyStep;
        default:
          return false;
      }
    },
    isStepValid: (activeStep: number): boolean => {
      switch (activeStep + 1) {
        case 1:
          return validStep1.validStep;
        case 2:
          return validStep2.validStep;
        case 3:
          return validStep3.validStep;
        case 4:
          return validStep4a.validStep && validStep4b.validStep;
        case 5:
          return validStep5.validStep;
        default:
          return false;
      }
    },
    setDirtyStep: (activeStep: number, dirty: boolean) => {
      switch (activeStep + 1) {
        case 1:
          setDirtyStep1(dirty);
          break;
        case 2:
          setDirtyStep2(dirty);
          break;
        case 3:
          setDirtyStep3(dirty);
          break;
        case 4:
          setDirtyStep4(dirty);
          break;
        case 5:
          setDirtyStep5(dirty);
          break;
      }
    },
  };
};
