export const getLeaderName = (
  entityType: string,
  lowercase: boolean = false
): string => {
  if (lowercase) {
    return getLeaderName(entityType, false).toLowerCase();
  }
  if (entityType === "SKOLE") {
    return "Rektor";
  } else if (entityType === "BARNEHAGE") {
    return "Styrer";
  }
  return "Leder";
};

export const getEntityName = (
  entityType: string,
  lowercase: boolean = false
): string => {
  if (lowercase) {
    return getEntityName(entityType, false).toLowerCase();
  }

  if (entityType === "SKOLE") {
    return "Skole";
  } else if (entityType === "BARNEHAGE") {
    return "Barnehage";
  }
  return "Navn";
};
