import {
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
} from "@mui/material";
import { RADIO_BOOLEAN, SignupFormResp } from "../data/useSignupForm";
import { SignupFormValidatorResp } from "../data/useSignupFormValidator";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PersonContactForm from "./PersonContactForm";
import { InfoTooltip } from "../styles/styledTooltip";

interface SubmitterFormProps {
  signupdata: SignupFormResp;
  validator: SignupFormValidatorResp;
}

export default function SubmitterForm(props: SubmitterFormProps) {
  const isSfoLeader = props.signupdata.departmentLeaderIsSubmitter;
  const isSkole = props.signupdata.entityData.entityType === "SKOLE";
  const isBarnehage = props.signupdata.entityData.entityType === "BARNEHAGE";

  return (
    <>
      <PersonContactForm
        signupdata={props.signupdata}
        key="innsender"
        entityType="submitter"
        title="4. Hvem er du?"
        stepValidation={props.validator.validStep4a}
      />

      {isSkole && (
        <FormControl
          sx={{ marginTop: "25px" }}
          error={
            props.validator.validStep4b.dirtyStep &&
            props.validator.validStep4b.validStepDetails[
              "departmentLeaderIsSubmitter"
            ]
              ? true
              : false
          }
          required
        >
          <FormLabel id="demo-row-radio-buttons-group-label">
            Er du SFO-leder?
          </FormLabel>
          <RadioGroup
            row
            value={isSfoLeader}
            onChange={(event) => {
              props.signupdata.setDepartmentLeaderIsSubmitter(
                event.target.value
              );
            }}
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel
              sx={{ marginLeft: "5px", marginTop: "5px" }}
              value={RADIO_BOOLEAN.TRUE}
              control={<Radio sx={{ marginLeft: "5px" }} />}
              label="Ja"
            />
            <FormControlLabel
              sx={{ marginTop: "5px" }}
              value={RADIO_BOOLEAN.FALSE}
              control={<Radio sx={{ marginLeft: "15px" }} />}
              label="Nei"
            />
          </RadioGroup>
          <FormHelperText>
            {props.validator.validStep4b.dirtyStep
              ? props.validator.validStep4b.validStepDetails[
                  "departmentLeaderIsSubmitter"
                ]
              : ""}
          </FormHelperText>
        </FormControl>
      )}

      {isBarnehage && (
        <FormControl
          sx={{ marginTop: "25px" }}
          error={
            props.validator.validStep4b.dirtyStep &&
            props.validator.validStep4b.validStepDetails["leaderIsSubmitter"]
              ? true
              : false
          }
          required
        >
          <FormLabel id="demo-row-radio-buttons-group-label">
            Er du barnehagestyrer?
            <InfoTooltip
              title="Hvorfor spør vi om dette?"
              description="Påmeldingen må godkjennes av barnehagestyrer. Dersom du selv ikke er styrer i barnehagen kan du legge inn kontaktinfo til barnehagestyrer i neste steg."
            >
              <div
                style={{
                  float: "right",
                  padding: "0px 0px 0px 10px",
                  cursor: "pointer",
                }}
              >
                <InfoOutlinedIcon />
              </div>
            </InfoTooltip>
          </FormLabel>
          <RadioGroup
            row
            value={props.signupdata.leaderIsSubmitter}
            onChange={(event) => {
              props.signupdata.setLeaderIsSubmitter(event.target.value);
            }}
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel
              sx={{ marginLeft: "5px", marginTop: "5px" }}
              value={RADIO_BOOLEAN.TRUE}
              control={<Radio sx={{ marginLeft: "5px" }} />}
              label="Ja"
            />
            <FormControlLabel
              sx={{ marginTop: "5px" }}
              value={RADIO_BOOLEAN.FALSE}
              control={<Radio sx={{ marginLeft: "15px" }} />}
              label="Nei"
            />
          </RadioGroup>
          <FormHelperText>
            {props.validator.validStep4b.dirtyStep
              ? props.validator.validStep4b.validStepDetails[
                  "leaderIsSubmitter"
                ]
              : ""}
          </FormHelperText>
        </FormControl>
      )}

      {isSkole && isSfoLeader === "no" && (
        <Box sx={{ marginTop: "45px", marginBottom: "25px" }}>
          <PersonContactForm
            signupdata={props.signupdata}
            key="departmentleader"
            entityType="departmentleader"
            title="4.2. SFO-leder"
            stepValidation={props.validator.validStep4b}
          />
        </Box>
      )}
    </>
  );
}
