import EntityDetailsForm from "./EntityDetailsForm";
import PersonContactForm from "./PersonContactForm";
import FinnEntityForm from "./FinnEntityForm";
import SelectUnitType from "./SelectUnitType";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  CircularProgress,
  Box,
  Button,
  Typography,
  Grid,
  useMediaQuery,
} from "@mui/material";
import {
  RADIO_BOOLEAN,
  SignupFormResp,
  useSignupForm,
} from "../data/useSignupForm";
import { SERVICES } from "../services";
import {
  SignupFormValidatorResp,
  useSignupFormValidator,
} from "../data/useSignupFormValidator";
import { StyledPaperAmber, StyledPaperGreen } from "../styles/styledComponents";
import { matjungelenGreen } from "../styles/colors";
import FormSentError from "./FormSentError";
import SubmitterForm from "./SubmitterForm";
import {
  StyledStepper,
  StyledStepperMode,
  TOTAL_STEPS,
} from "../styles/styledSteppers";
import { getLeaderName } from "../constants";
import SignupFormSummary from "./SignupFormSummary";

interface SignupFormProps {
  enhet?: string;
}

const globalSessionId = uuidv4().toString() as string;

const getStepContent = (
  step: number,
  signupdata: SignupFormResp,
  validator: SignupFormValidatorResp,
  nextStep: CallableFunction
) => {
  switch (step) {
    case 0:
      return (
        <SelectUnitType
          signupdata={signupdata}
          stepValidation={validator.validStep1}
        />
      );
    case 1:
      return (
        <FinnEntityForm
          signupdata={signupdata}
          stepValidation={validator.validStep2}
          nextStep={nextStep}
        />
      );
    case 2:
      return (
        <EntityDetailsForm
          signupdata={signupdata}
          stepValidation={validator.validStep3}
        />
      );
    case 3:
      return (
        <SubmitterForm
          key="innsender"
          signupdata={signupdata}
          validator={validator}
        />
      );
    case 4:
      return (
        <PersonContactForm
          signupdata={signupdata}
          key="leder"
          entityType="leader"
          title={`5. ${getLeaderName(signupdata.entityData.entityType)}`}
          stepValidation={validator.validStep5}
          info={{
            text: "Hvorfor spør vi om dette?",
            width: "110px",
            description: `Påmeldingen må godkjennes av ${
              signupdata.entityData.entityType === "BARNEHAGE"
                ? "barnehagestyrer"
                : "rektor"
            }. Vi trenger ${
              signupdata.entityData.entityType === "BARNEHAGE"
                ? "styrers"
                : "rektors"
            } kontaktinfo for å kunne sende lenke til godkjenning av påmeldingen.`,
          }}
        />
      );
    case 5:
      return <SignupFormSummary signupdata={signupdata} />;
    default:
      throw new Error("Unknown step");
  }
};

const transferDataBetweenSteps = (
  activeStep: number,
  signupdata: SignupFormResp
) => {
  if (activeStep === 3 && signupdata.entityData.entityType === "BARNEHAGE") {
    if (signupdata.leaderIsSubmitter === RADIO_BOOLEAN.TRUE) {
      signupdata.patchLeader({ ...signupdata.submitter });
    } else {
      signupdata.patchLeader({
        navn: "",
        epost: "",
        telefon: "",
      });
    }
  }
};

export default function SignupForm(props: SignupFormProps) {
  const signupdata = useSignupForm();
  const signupdataValidator = useSignupFormValidator(signupdata);
  const [activeStep, setActiveStep] = useState(0);
  const [formSent, setFormSent] = useState(false);
  const [formSentFailed, setFormSentFailed] = useState(false);
  const hideStepperMenu = useMediaQuery("(max-width:900px)");
  const leaderName = getLeaderName(signupdata.entityData.entityType);

  useEffect(() => {
    if (props.enhet && activeStep === 0) {
      setActiveStep(1);
      signupdata.patchEntityData({
        entityType: props.enhet === "sfo" ? "SKOLE" : "BARNEHAGE",
        navn: "",
        orgnr: "",
        address: "",
        postalcode: "",
        postalname: "",
        numberOfChildren: "",
        numberOfEmployees: "",
      });
    }
  }, [activeStep, props.enhet]);

  useEffect(() => {
    const postContract = async () => {
      await postAnalytics();
      const success = await SERVICES.registerContract(
        signupdata.entityData,
        signupdata.submitter,
        signupdata.departmentLeaderIsSubmitter
          ? signupdata.submitter
          : signupdata.departmentleader,
        signupdata.leader
      );

      if (!success) {
        setFormSent(false);
        setFormSentFailed(true);
        setActiveStep(activeStep - 1);
      } else {
        setFormSent(true);
      }
    };
    const postAnalytics = async () => {
      await SERVICES.postAnalyticsData(
        globalSessionId,
        activeStep,
        signupdata.entityData,
        signupdata.submitter,
        signupdata.departmentLeaderIsSubmitter
          ? signupdata.submitter
          : signupdata.departmentleader,
        signupdata.leader
      );
    };
    if (activeStep === TOTAL_STEPS) {
      postContract();
    } else {
      postAnalytics();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  if (formSentFailed) {
    return (
      <StyledPaperAmber
        variant="outlined"
        sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <FormSentError />
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          {activeStep !== 0 && (
            <Button
              variant="contained"
              onClick={() => setFormSentFailed(false)}
              disableElevation
              sx={{
                mt: 3,
                ml: 1,
                width: "150px",
              }}
            >
              Tilbake
            </Button>
          )}
          <Box component="div" sx={{ flexGrow: 1 }}></Box>
          <Button
            variant="contained"
            onClick={() => {
              setFormSentFailed(false);
              setActiveStep(activeStep + 1);
            }}
            disableElevation
            sx={{ mt: 3, ml: 1 }}
          >
            Send inn på nytt
          </Button>
        </Box>
      </StyledPaperAmber>
    );
  }

  if (formSent) {
    return (
      <StyledPaperAmber
        variant="outlined"
        sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <Typography component="h1" variant="h4" align="center">
          Tusen takk
        </Typography>
        <Box sx={{ padding: "15px" }}>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            <p>
              Vi har registrert påmeldingen og en e-post med lenke til
              godkjenning av vilkår er sendt til {leaderName} på{" "}
              {signupdata.leader.epost}.
            </p>
          </Typography>
          <Typography variant="subtitle2">
            <p>
              Nå er dere snart med i Matjungelen! Siste steg før dere blir
              påmeldt er altså at {leaderName} godkjenner vilkår sendt på epost.
              Vi sender deg en bekreftelse når {leaderName} har godkjent
              vilkårene.{" "}
            </p>
            <p>
              Finner dere ikke e-posten? Sjekk spamfilteret eller ta kontakt på{" "}
              <a href="mailto:post@matjungelen.no">post@matjungelen.no</a> så
              kan vi sende på nytt.
            </p>
          </Typography>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            component="a"
            href="https://matjungelen.no"
            variant="text"
            sx={{ margin: "25px 0px", fontWeight: "bold" }}
            disableElevation
          >
            Gå tilbake til matjungelen.no &raquo;
          </Button>
        </Box>
      </StyledPaperAmber>
    );
  }

  return (
    <Grid container spacing={2}>
      {!hideStepperMenu && (
        <Grid item xs={4}>
          <StyledPaperGreen
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
          >
            <StyledStepper
              mode={StyledStepperMode.VERTICAL}
              activeStep={activeStep}
              entityType={signupdata.entityData.entityType}
              onChangeStep={(step: number) => setActiveStep(step)}
            />
          </StyledPaperGreen>
        </Grid>
      )}
      <Grid item xs={hideStepperMenu ? 12 : 8}>
        <StyledPaperAmber
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Typography
            component="h1"
            variant="h4"
            align="center"
            sx={{
              fontFamily: '"Unica One", "Open Sans", Roboto',
              fontSize: "35px",
              textTransform: "uppercase",
              paddingBottom: "15px",
            }}
          >
            Påmelding
          </Typography>

          {hideStepperMenu && (
            <StyledStepper
              mode={StyledStepperMode.HORIZONTAL}
              activeStep={activeStep}
              entityType={signupdata.entityData.entityType}
              onChangeStep={(step: number) => setActiveStep(step)}
            />
          )}

          {activeStep === TOTAL_STEPS ? (
            <center>
              <CircularProgress />
            </center>
          ) : (
            <>
              {getStepContent(activeStep, signupdata, signupdataValidator, () =>
                setActiveStep(activeStep + 1)
              )}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "25px",
                }}
              >
                {activeStep !== 0 && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (signupdata.customOrganization && activeStep <= 2) {
                        signupdata.setCustomOrganization(false);
                      }

                      setActiveStep(activeStep - 1);
                    }}
                    disableElevation
                    sx={{
                      mt: 3,
                      ml: 1,
                      width: "200px",
                      background: "white",
                      color: matjungelenGreen,
                      border: `1px solid ${matjungelenGreen}`,
                      "&:hover": {
                        background: "rgb(37, 65, 18)",
                        color: "white",
                      },
                    }}
                  >
                    Tilbake
                  </Button>
                )}
                <Box component="div" sx={{ flexGrow: 1 }}></Box>
                <Button
                  variant="contained"
                  onClick={() => {
                    if (
                      signupdataValidator.isStepValid(activeStep) ||
                      activeStep === TOTAL_STEPS - 1
                    ) {
                      transferDataBetweenSteps(activeStep, signupdata);
                      setActiveStep(activeStep + 1);
                    } else {
                      signupdataValidator.setDirtyStep(activeStep, true);
                    }
                  }}
                  disableElevation
                  sx={{
                    mt: 3,
                    ml: 1,
                    width: "200px",
                  }}
                  disabled={
                    signupdataValidator.isStepDirty(activeStep)
                      ? !signupdataValidator.isStepValid(activeStep)
                      : false
                  }
                >
                  {activeStep === TOTAL_STEPS - 1
                    ? "Send inn påmelding"
                    : "Neste"}
                </Button>
              </Box>
            </>
          )}
        </StyledPaperAmber>
      </Grid>
    </Grid>
  );
}
