import {
  Grid,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { SignupFormResp } from "../data/useSignupForm";
import { StepValidateDetails } from "../data/useSignupFormValidator";

export interface SelectUnitTypeProps {
  signupdata: SignupFormResp;
  stepValidation: StepValidateDetails;
}

export default function SelectUnitType(props: SelectUnitTypeProps) {
  const handleChange = (event: any) =>
    props.signupdata.patchEntityData({
      entityType: event.target.value,
      navn: "",
      orgnr: "",
      address: "",
      postalcode: "",
      postalname: "",
      numberOfChildren: "",
      numberOfEmployees: "",
    });

  return (
    <>
      <Typography component="h2" sx={{ fontWeight: "bold" }}>
        1. Velg type organisasjon
      </Typography>
      <Grid container spacing={3} sx={{ marginTop: 0 }}>
        <Grid item xs={12}>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
              value={props.signupdata.entityData.entityType}
              onChange={handleChange}
            >
              <FormControlLabel
                value="BARNEHAGE"
                control={<Radio />}
                label="Barnehage"
              />
              <FormControlLabel
                value="SKOLE"
                control={<Radio />}
                label="Skole / SFO"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}
