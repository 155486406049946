import CheckIcon from "@mui/icons-material/Check";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTheme } from "@mui/material/styles";
import { matjungelenDarkGreen, matjungelenGreen } from "./colors";
import {
  Stepper,
  Step,
  StepLabel,
  useMediaQuery,
  MobileStepper,
  Box,
} from "@mui/material";

export const TOTAL_STEPS = 6;

export enum StyledStepperMode {
  HORIZONTAL,
  VERTICAL,
}

interface StyledStepperProps {
  mode: StyledStepperMode;
  entityType: string;
  activeStep: number;
  onChangeStep: CallableFunction;
}

interface StepperProps {
  steps: string[];
  activeStep: number;
  onChangeStep: CallableFunction;
}

const CheckCircleIconGreen = () => <CheckCircleIcon color="primary" />;

const VerticalStepper = (props: StepperProps) => {
  const { steps, activeStep } = props;
  const getStepIcon = (idx: number) => {
    if (activeStep > TOTAL_STEPS - 1 && idx < TOTAL_STEPS) {
      return CheckIcon;
    } else {
      return undefined;
    }
  };

  return (
    <Stepper
      orientation="vertical"
      activeStep={Math.min(TOTAL_STEPS - 1, activeStep)}
      sx={{ pt: 3, pb: 5 }}
    >
      {steps.map((label, idx) => (
        <Step
          key={label}
          sx={{
            "&.MuiStep-root": {
              background:
                activeStep === idx ? matjungelenDarkGreen : matjungelenGreen,
              paddingLeft: "10px",
              marginLeft: "-10px",
              color: "white",
              borderRadius: "5px",
              fontWeight: 600,
            },
            "& .MuiStepLabel-iconContainer.Mui-completed": {
              svg: {
                color: matjungelenDarkGreen,
                background: "white",
                borderRadius: "15px",
                border: `1px solid ${matjungelenDarkGreen}`,
                outline: `3px solid ${matjungelenDarkGreen}`,
                outlineOffset: "-3px",
              },
            },
          }}
        >
          <StepLabel
            onClick={() => {
              if (activeStep > idx) {
                props.onChangeStep(idx);
              }
            }}
            StepIconComponent={getStepIcon(idx)}
            sx={{
              "&": {
                cursor: activeStep > idx ? "pointer" : "default",
              },
              ".MuiStepLabel-label": {
                color: activeStep > idx ? "white" : "rgba(255, 255, 255, 0.25)",
                fontWeight: 600,
              },
              "&.MuiStep-root": {
                color: "white",
                fontWeight: 600,
              },
              "& .MuiStepLabel-label.Mui-completed": {
                color: "white",
                fontWeight: 600,
              },
              "& .MuiStepLabel-label.Mui-active": {
                color: "white",
                fontWeight: 600,
              },
            }}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

const HorizontalStepper = (props: StepperProps) => {
  const { steps, activeStep } = props;
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isXs = useMediaQuery("(max-width:400px)");
  const alternativeLabel = useMediaQuery("(max-width:900px)");

  const showLabel = (s: number): boolean => {
    if (isSm) return false;
    return true;
  };

  const getStepIcon = (idx: number) => {
    if (activeStep > 4 && idx < 5) {
      return CheckCircleIconGreen;
    } else {
      return undefined;
    }
  };

  if (isXs) {
    return (
      <MobileStepper
        variant="dots"
        steps={5}
        position="static"
        activeStep={activeStep}
        nextButton={<Box component="div"></Box>}
        backButton={<Box component="div"></Box>}
        sx={{ background: "transparent", margin: "15px 0px" }}
      />
    );
  } else {
    return (
      <Stepper
        activeStep={Math.min(4, activeStep)}
        sx={{ pt: 3, pb: 5 }}
        alternativeLabel={alternativeLabel}
      >
        {steps.map((label, idx) => (
          <Step key={label}>
            <StepLabel StepIconComponent={getStepIcon(idx)}>
              {showLabel(idx) ? label : ""}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    );
  }
};

export const StyledStepper = (props: StyledStepperProps) => {
  const { mode, entityType, activeStep } = props;
  let steps = [
    "Organisasjon",
    "Organisasjon",
    "Detaljer",
    "Litt om deg",
    "Leder",
    "Oppsummering",
  ];
  if (entityType === "SKOLE") {
    steps = [
      "Organisasjon",
      "Finn skole",
      "Detaljer",
      "Litt om deg",
      "Rektor",
      "Oppsummering",
    ];
  } else {
    steps = [
      "Organisasjon",
      "Finn barnehage",
      "Detaljer",
      "Litt om deg",
      "Styrer",
      "Oppsummering",
    ];
  }

  if (mode === StyledStepperMode.VERTICAL) {
    return (
      <VerticalStepper
        activeStep={activeStep}
        steps={steps}
        onChangeStep={props.onChangeStep}
      />
    );
  } else {
    return (
      <HorizontalStepper
        activeStep={activeStep}
        steps={steps}
        onChangeStep={props.onChangeStep}
      />
    );
  }
};
