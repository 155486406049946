import { Alert, AlertTitle, Button } from "@mui/material";
import { ReactElement, useState } from "react";

export interface ClickableInfoBoxProps {
  title: string;
  children: ReactElement[] | ReactElement;
}

export default function ClickableInfoBox(props: ClickableInfoBoxProps) {
  const [visibleInfo, setVisibleInfo] = useState(false);

  return (
    <>
      {!visibleInfo && (
        <Button
          variant="text"
          sx={{ marginTop: "10px", textTransform: "none" }}
          onClick={() => setVisibleInfo(true)}
        >
          {props.title}
        </Button>
      )}

      {visibleInfo && (
        <Alert severity="info" variant="outlined" sx={{ marginTop: "15px" }}>
          <AlertTitle>{props.title}</AlertTitle>
          {props.children}
        </Alert>
      )}
    </>
  );
}
