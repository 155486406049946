import { useEffect, useState } from "react";
import { SERVICES } from "../services";

export interface useSignatureDataResp {
  successSigning: boolean;
  loading: boolean;
  found: boolean;
  failedSigning: boolean;
  signatureData?: ISignatureData;
  confirmSignature: CallableFunction;
}

export const useSignatureData = (code?: string): useSignatureDataResp => {
  const [signatureData, setSignatureData] = useState<ISignatureData>();
  const [successSigning, setSuccessSigning] = useState(false);
  const [failedSigning, setFailedSigning] = useState(false);
  const [loading, setLoading] = useState(true);
  const [found, setFound] = useState(false);

  useEffect(() => {
    const load = async () => {
      if (code) {
        setLoading(true);
        try {
          const newSignatureData = await SERVICES.getSignatureData(code);
          setSignatureData(newSignatureData);
          setFound(true);
        } catch (e) {
          setFound(false);
        }

        setLoading(false);
      }
    };

    if (code) {
      load();
    }
  }, [code]);

  return {
    failedSigning,
    successSigning,
    loading,
    found,
    signatureData,
    confirmSignature: async (): Promise<boolean> => {
      if (signatureData?.id) {
        setLoading(true);
        const success = await SERVICES.registerContractSignature(
          signatureData?.id
        );

        setFailedSigning(!success);
        setSuccessSigning(success);
        setLoading(false);
      }
      return false;
    },
  };
};
