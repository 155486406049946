import { useEffect, useState } from "react";
import { SERVICES } from "../services";
import { validOrgNr } from "../validatorUtils";

export interface useContractDataResp {
  foundExistingContract: boolean;
  loading: boolean;
}

export const useContractData = (orgnr: string): useContractDataResp => {
  const [loading, setLoading] = useState(true);
  const [foundExistingContract, setFoundExistingContract] =
    useState<boolean>(false);

  useEffect(() => {
    const load = async () => {
      if (`${orgnr}`.length === 9 && validOrgNr(orgnr)) {
        setLoading(true);
        const newContractData = await SERVICES.getContractByOrgnr(orgnr);
        const { found } = newContractData || { found: false };
        setFoundExistingContract(found);
        setLoading(false);
      } else {
        setLoading(false);
        setFoundExistingContract(false);
      }
    };

    if (orgnr) {
      load();
    } else {
      setLoading(false);
    }
  }, [orgnr]);

  return {
    foundExistingContract,
    loading,
  };
};
