import { useEffect, useState } from "react";
import { SERVICES } from "../services";

export interface useBarnehageDataResp {
  barnehager: IBarnehage[];
  visibleBarnehager: withLabel<IBarnehage>[];
  loading: boolean;
}
export interface useBarnehageDataProps {
  selectedFylke: IFylke | null;
  selectedKommune: IKommune | null;
}

export const useBarnehageData = (
  props: useBarnehageDataProps
): useBarnehageDataResp => {
  const { selectedFylke, selectedKommune } = props;
  const [loading, setLoading] = useState(true);
  const [barnehager, setBarnehager] = useState<IBarnehage[]>([]);

  useEffect(() => {
    const load = async () => {
      if (selectedFylke && selectedKommune) {
        setLoading(true);
        const newBarnehager = await SERVICES.getBarnehager(
          selectedFylke.fylkesnummer,
          selectedKommune.kommunenummer
        );
        setBarnehager(newBarnehager);
        setLoading(false);
      }
    };

    if (selectedFylke && selectedKommune) {
      load();
    }
  }, [selectedFylke, selectedKommune]);

  const visibleBarnehager = (barnehager || []).map(
    (d) =>
      ({
        label: d.navn,
        ...d,
      } as withLabel<IBarnehage>)
  );

  return {
    loading,
    barnehager,
    visibleBarnehager,
  };
};
