import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { Alert, AlertTitle } from "@mui/material";
import { ReactElement } from "react";
import { matjungelenAmber, matjungelenGreen } from "./colors";

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: "0px",
    maxWidth: "100%",
    width: 350,
    fontSize: theme.typography.pxToRem(14),
    border: `1px solid ${matjungelenGreen}`,
    lineHeight: "25px",
    boxShadow: "0px 0px 5px 5px rgba(0,0,0,0.1)",
  },
  [`& .${tooltipClasses.tooltip} .MuiPaper-root`]: {
    backgroundColor: matjungelenAmber,
  },
  [`& .${tooltipClasses.tooltip} .MuiAlert-icon`]: {
    color: matjungelenGreen,
  },
}));

interface TitledTooltipProps {
  title: string;
  description: string;
  children: ReactElement;
}
export const InfoTooltip = (props: TitledTooltipProps) => (
  <HtmlTooltip
    title={
      <>
        <Alert severity="info">
          <AlertTitle>{props.title}</AlertTitle>
          {props.description}
        </Alert>
      </>
    }
    arrow
    enterDelay={10}
    leaveDelay={500}
    enterTouchDelay={100}
    leaveTouchDelay={3500}
  >
    {props.children}
  </HtmlTooltip>
);
