import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

export const WhiteTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    background: "#FCFBF6",
  },
  "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
    background: "#FCFBF6",
  },
  "&:hover .MuiInputBase-root": {
    background: "#FCFBF6",
  },
  "&.Mui-disabled": {
    background: "#FCFBF6",
  },
  "&.Mui-expanded": {
    background: "red",
  },
  "& .Mui-expanded": {
    background: "red",
  },
  "& .MuiFilledInput-root.Mui-disabled": {
    background: "#FCFBF6",
  },
});
